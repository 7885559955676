"use client";
import { usePathname, useRouter, useSearchParams } from "next/navigation";
import { useEffect } from "react";

export default function PathController() {
	const pathName = usePathname();
	const params = useSearchParams();
	const router = useRouter();

	useEffect(() => {
		if (params?.toString() !== "" && pathName) {
			if (
				!params?.toString().toLowerCase().includes("utm") &&
				!params?.toString().toLowerCase().includes("id") &&
				!params?.toString().toLowerCase().includes("channel") &&
				!params?.toString().toLowerCase().includes("pbjs_debug") &&
				!params?.toString().toLowerCase().includes("canal") &&
				!params?.toString().toLowerCase().includes("lbentry") &&
				!params?.toString().toLowerCase().includes("_gl")
			) {
				const path = pathName.split("?")[0];
				window?.history?.pushState(window?.history?.state, "", path);
			} else {
				const path = params.toString();
				if (path.endsWith("/") || path.endsWith("%2F")) {
					const pathReplace = path.replace(/\/$/, "").replace(/%2F$/, "");
					window?.history?.pushState(
						window?.history?.state,
						"",
						`?${pathReplace}`
					);
				}
				if (params?.toString().toLowerCase().includes("lbentry")) {
					// * Redirect the liveblog entries
					const lbPath = params?.get("lb");
					const lbEID = params?.get("lbentry");
					const newPath = `${lbPath}`;
					if (lbEID && lbPath) router.push(newPath);
					localStorage.setItem("lbHashId", lbEID || "");
				}
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pathName, params]);

	return null;
}
